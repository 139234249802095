.cm-facts {
  display: grid;
  gap: var(--gutter);
  grid-template-columns: repeat(2, 1fr);
  @media only screen and (min-width : 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (min-width : 1200px) {
    grid-template-columns: repeat(6, 1fr);
  }
  dl {
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  dt {
    order: 2;
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
  }
  dd {
    order: 1;
    margin: 0;
    padding: 0;
    font-size: 1.6667rem;
  }
}