.cm-form-contact {
  display: grid;
  gap: var(--gutter);
  @media only screen and (min-width : 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .form-group--message,
  .form-group--submit,
  .alert  {
    grid-column: 1/-1;
  }
}