.cm-downloads {
  margin: 0;
  padding: 0;
  list-style: none;
}
.cm-download {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid var(--gray-darker);

  & > * {
    display: inline-grid;
    grid-template-columns: min-content auto;
    gap: .5em;
    padding-block: .25em;
    border: 0;
  }
}