.site-navbar {
  $this: &;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  gap: var(--gutter);
  padding-block: calc(var(--gutter) * 2);
  transition: .2s ease;
  transition-property: padding;
  &__logo {
    font-weight: 600;
    border: 0;
    img {
      display: block;
      width: 10rem;
      height: auto;
      transition: .2s ease;
      transition-property: width, height;
    }
  }

  &__lang {
    margin-left: auto;
    text-transform: uppercase;
    ul {
      display: flex;
      gap: 1rem;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .current {
      display: none;
    }
  }

  &__toggle {
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    border: 0;
    padding: .25rem;
    background-color: transparent;
    svg {
      display: block;
      width: 100%;
      height: auto;
    }
    line {
      stroke-width: 2;
      stroke: var(--blue);
      stroke-linecap: round;
    }
  }

  &__nav {
    display: none;
    position: absolute;
    z-index: 1020;
    left: 0;
    right: 0;
    top: 100%;
    padding-block: var(--section-gap);
    background-color: var(--blue);
    color: #fff;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    // ul ul {
    //   display: none;
    // }
    li {
      margin: 0;
      padding: 0;
    }
    a {
      font-weight: 200;
      color: rgba(255,255,255,.75);
      &:hover {
        color: #fff;
        border: 0;
      }
    }

    .nv-main {
      position: relative;
      font-size: 1.6667rem;
      text-transform: uppercase;
      li {
        margin: 0;
        padding: 0;
      }
      a {
        display: block;
        padding: .25em 0;
        border-bottom: solid rgba(255,255,255,.75) 1px;
        &::after {
          content: ' \00BB';
        }
      }
      .current {
        & > a {
          color: #fff;
        }
        // & > ul {
        //   opacity: 1;
        //   z-index: 1;
        // }
      } 
      ul ul {
        text-transform: none;
        font-size: 1.25rem;
        a {
          border: 0;
        }
      }
      @media only screen and (min-width : 768px) {
        & > ul {
          width: 50%;
          & > li {
            padding-right: calc(var(--gutter) / 2);
          }
        }
        & > ul ul {
          position: absolute;
          z-index: -1;
          top: 0;
          left: 50%;
          width: 50%;
          padding-left: calc(var(--gutter) / 2);
          font-size: 1.6667rem;
          opacity: 0;
          transition: opacity .2s ease;
          a {
            border-bottom: solid rgba(255,255,255,.75) 1px;
          }
        }
        & > ul > li:hover > ul {
          opacity: 1;
          z-index: 1;
        }
      }
    }

    .nv-lang {
      text-transform: uppercase;
      ul {
        display: flex;
      }
      li:not(:last-child)::after {
        content: '|';
        margin-inline: .375em;
      }
      a {
        text-decoration: none;
      }
      
    }
    &[aria-expanded=true] {
      display: grid;
    }
  }

  &.reduced {
    padding-block: var(--gutter);
    #{$this}__logo {
      img {
        width: 5.5rem;
      }
    }
  }
}