body {
  --input-btn-padding-y: .5em;
  --input-btn-padding-x: .5em;
  --input-btn-border-width: 1px;
  --input-btn-border-radius: 0;
  --input-btn-border-color: var(--gray);

  --input-background-color: #fff;
  --input-placeholder-color: var(--gray);

  // --btn-background-color: var(--primary);
  // --btn-border-color: var(--primary);
  --btn-color: #fff;
  --btn-padding-x: 1em;
  --btn-padding-y: var(--input-btn-padding-y);
}

input[type=text],
input[type=password],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=week],
select,
textarea { 
  display: inline-block;
  width:100%;
  margin:0;
  padding: var(--input-btn-padding-y) var(--input-btn-padding-x);
  border-style: solid;
  border-radius: var(--input-btn-border-radius);
  border-width: var(--input-btn-border-width);
  border-color: var(--input-btn-border-color);
  background-color: var(--input-background-color);
  font: inherit;
  transition: .2s ease;
  transition-property: border-color;
}
input[type=text]:focus,
input[type=text]:focus-visible,
input[type=password]:focus,
input[type=date]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=email]:focus,
input[type=month]:focus,
input[type=number]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=time]:focus,
input[type=url]:focus,
input[type=week]:focus,
select:focus,
textarea:focus {
  margin:0;
  border-color: var(--input-btn-border-color);
  outline: 0;
}
textarea {
  height:12rem;
}
::placeholder {
  color: var(--input-placeholder-color);
}

.btn {
  display: inline-block;
  cursor: pointer;
  color: var(--btn-color);
  // background-color: var(--btn-background-color);
  // border-color: var(--btn-border-color);
  background-color: var(--primary);
  border-color: var(--primary);
  border-width: var(--input-btn-border-width);
  border-radius: var(--input-btn-border-radius);
  border-style: solid;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font: inherit;
  font-weight: 700;
  transition: .2s ease;
  transition-property: background-color, border-color;
  &:hover {
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
    color: #fff;
  }
}

.alert {
  background-color: var(--gray-light);
  padding: var(--gutter);
}