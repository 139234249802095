.cm-simple-search {
  display: flex;
  flex-direction: column;
  gap: var(--section-gap);
  &__form {
    display: flex;
    gap: .5em;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__result {
    margin: 0 0 var(--section-gap) 0;
    padding: 0 0 var(--section-gap) 0;
    border-bottom: solid var(--gray) 1px;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}