html {
  font-family: 'Noto Sans',sans-serif;
  font-weight: 300;
  line-height: 1.4;
}
@media only screen and (min-width : 992px) {
  html {
    font-size: 18px;
  }
}

h1 {
  font-size: 1.6667rem;
  line-height: 1.1;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--blue);
}
h2 {
  font-size: 1.6667rem;
  line-height: 1.1;
  font-weight: 200;
  text-transform: uppercase;
  color: var(--gray-dark);
}
h3 {
  font-size: 1.25rem;
  line-height: 1.1;
  font-weight: 600;
}
h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
}
a {
  color: var(--primary);
  text-decoration: none;
  border-bottom: solid var(--primary) 1px;
  font-weight: 600;
  transition: color .2s ease;
  &:hover {
    color: var(--primary-dark);
    border-color: var(--primary-dark);
  }
}
b,strong {
  font-weight: 600;
}

figure {
  margin: 0;
  padding: 0;
  figcaption {
    margin: 0;
    padding: 0;
  }
}

[data-link]:not([data-link=""]) {
  cursor: pointer;
}

ul {
  list-style: square;
  padding-left: 1.25em;
  li::marker {
    color: var(--blue);
  }
}

table {
  margin: 0;
  width: 100%;
  th,td {
    text-align: left;
    vertical-align: top;
    padding: .375em 1em .375em 0;
    border-block: solid var(--gray-light) 1px;
  }
  th {
    font-weight: 600;
  }
}