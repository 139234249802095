.section {
  padding-block: var(--section-padding);
  // &:nth-child(even) {
  //   background-color: var(--gray-light);
  // }
  
  // &__contentcollection > * {
  //   max-width: 65rem;
  //   margin-block: var(--section-gap);
  //   margin-inline: auto;
  //   &:first-child {
  //     margin-top: 0;
  //   }
  //   &:last-child {
  //     margin-bottom: 0;
  //   }
  // }
}