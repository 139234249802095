.cm-accordion {
  overflow: hidden;
  $this: &;
  --_summary-padding: 1rem;
  --_icon-size: 6.1111rem;
  &__summary {
    cursor: pointer;
    background-color: var(--blue);
    color: #fff;
    padding: var(--_summary-padding);
    display: flex;
    gap: var(--gutter);
    align-items: center;
    list-style: none; /* Firefox */
    &::-webkit-details-marker {
      display: none; /* Chrome */
    }
    .icon {
      display: block;
      width: var(--_icon-size);
      height: auto;
    }
    .title {
      font-size: 1.6667rem;
      line-height: 1.1;
    }
    .indicator {
      margin-left: auto;
      display: block;
      width: 1.4444rem;
      height: auto;
      transition: rotate .2s ease;
      path {
        stroke: #fff;
        stroke-width: 4;
        stroke-linecap: round;
        fill: none;
      }
    }
  }
  &__content {
    padding-block: var(--gutter);
    display: grid;
    gap: var(--gutter);
  }
  &__image {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &__text {
    & > :first-child {
      margin-top: 0;
    }
    & > :last-child {
      margin-bottom: 0;
    }
  }

  &[open] {
    #{$this}__summary {
      .indicator {
        rotate: -180deg;
      }
    }
  }

  &:nth-child(even) {
    #{$this}__summary {
      background-color: var(--gray-light);
      color: var(--blue);
      .indicator {
        path {
          stroke: var(--blue);
        }
      }
    }
  }

  @media only screen and (min-width : 768px) {
    &.has-image {
      #{$this}__content {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &.has-image.is-image-small {
      #{$this}__content {
        grid-template-columns: 16em auto;
      }
      #{$this}__image {
        img {
          max-width: 16em;
          max-height: 16em;
          width: auto;
          height: auto;
          margin-inline: auto;
        }
      }
    }
  }

  // @media only screen and (min-width : 992px) {
  //   &.has-icon {
  //     #{$this}__content {
  //       padding-left: calc(var(--_summary-padding) + var(--_icon-size) + var(--gutter));
  //     }
  //   }
  // }
}