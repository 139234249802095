.cm-teaser-services {
  display: grid;
  // gap: var(--gutter);
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;gap: var(--gutter);
    grid-template-columns: 1fr;
    @media only screen and (min-width : 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (min-width : 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
    li {
      margin: 0;
      padding: 0;
    }
  }

  .teaser {
    padding: var(--gutter);
    transition: .2s ease;
    transition-property: color, background-color, border-color;
    &__icon {
      width: 100%;
      height: auto;
      display: block;
      max-width: 14rem;
      // margin-inline: auto;
    }
    &:hover {
      background-color: var(--blue-op50);
      color: #fff;
      .teaser__title {
        color: #fff;
      }
      a {
        color: #fff;
        border-color: #fff;
      }
    }
  }
}