.cm-teaser-topics {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--gutter);
  }
  &__teaser {
    margin: 0;
    padding: 0;
    background-color: var(--blue);
    color: #fff;
    .link {
      display: block;
      aspect-ratio: 2/1;
      color: #fff;
      text-decoration: none;
      border: 0;
      display: grid;
      grid-template-rows: auto 1fr;
      &::after {
        pointer-events: none;
        content: '';
        z-index: 2;
        display: block;
        grid-row: 2 / span 2;
        grid-column: 1;
        background-color: var(--blue);
        opacity: 0.6;
        transition: opacity .2s ease;
      }
    }
    .link:hover {
      border: 0;
      &::after {
        opacity: 0;
      }
    }
    .title {
      grid-row: 1;
      grid-column: 1;
      padding: .75rem 1rem;
      text-transform: uppercase;
      font-size: 1.6667rem;
      line-height: 1.1;
      font-weight: 700;
      background-color: var(--blue);
      z-index: 2;
    }
    .subtitle {
      grid-row: 3;
      grid-column: 1;
      z-index: 3;
      padding: .75rem 1rem;
      // background-image: var(--background-image);
      // background-size: cover;
      // background-position: 50% 50%;
    }
    .image {
      grid-row: 2 / span 2;
      grid-column: 1;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }

  @media only screen and (min-width : 768px) {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
    &__teaser {
      .link {
        aspect-ratio: 1;
      }
    }
  }
}