.cm-logos {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
}
.cm-logo {
  margin: 0;
  padding: 0;
  a {
    display: inline-block;
    border: 0;
  }
  img {
    display: inline-block;
    height: 4.4444em;
    width: auto;
  }
}