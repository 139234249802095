/* svg animation */
@keyframes menu1 {
  0% { transform: none; }
  50% { transform:translateY(6px); }
  100% { transform:translateY(6px) rotate(45deg); }
}
@keyframes menu2 {
  0% { opacity:1; }
  50% { opacity:1; }
  50% { opacity:1; transform: rotate(0deg); }
  100% { opacity:1; transform: rotate(-45deg); }
}
@keyframes menu3 {
  0% { transform: none; }
  50% { transform:translateY(-6px); }
  50% { opacity: 0;}
  100% { opacity: 0; fill: transparent; }
  // 100% { transform:translateY(-7.3px) rotate(-45deg); }
}
@keyframes menu1-reverse {
  0% { transform:translateY(6px) rotate(45deg); }
  50% { transform:translateY(6px); }
  100% { transform: none; }
}
@keyframes menu2-reverse {
  0% { transform: rotate(-45deg); }
  50% { transform: none; }
  100% { transform: none; }
}
@keyframes menu3-reverse {
  0% { transform:translateY(-6px); opacity: 0; }
  50% { transform:translateY(-6px); opacity: 0; }
  100% { transform: none; opacity: 1; }
}
.site-navbar__toggle {
  line {
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-duration: .5s;
  }
  line:nth-child(1) {
    transform-origin: 9.5px 4px;
  }
  line:nth-child(2) {
    transform-origin: 9.5px 10px;
  }
  line:nth-child(3) {
    transform-origin: 9.5px 16px;
  }
  &[aria-expanded=true] {
    line:nth-child(1) {
      animation-name: menu1;
    }
    line:nth-child(2) {
      animation-name: menu2;
    }
    line:nth-child(3) {
      animation-name: menu3;
    }
  }
  &[aria-expanded=false] {
    line:nth-child(1) {
      animation-name: menu1-reverse;
    }
    line:nth-child(2) {
      animation-name: menu2-reverse;
    }
    line:nth-child(3) {
      animation-name: menu3-reverse;
    }
  }
}