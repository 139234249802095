.site-footer {
  gap: 0;
  margin-top: auto;
  border-top: solid var(--blue) 5rem;
  // background-color: var(--gray-light);
  background-color: #fff;

  &__facts {
    background-color: var(--gray-light);
    padding-block: calc(var(--section-padding) / 2);    
  }

  &__deco {
    max-width: 70vw;
    height: auto;
    display: block;
    rect:nth-child(1) {
      fill: var(--blue-dark);
    }
    rect:nth-child(2) {
      fill: var(--blue);
    }
    rect:nth-child(3) {
      fill: var(--gray-dark);
    }
    rect:nth-child(4) {
      fill: var(--gray);
    }
  }

  &__content {
    padding-block: calc(var(--section-padding) / 2);
    display: grid;
    gap: var(--gutter);
    row-gap: var(--section-padding);
    grid-template-columns: 1fr;
    grid-template-areas: 'address'
                         'nav'
                         'certificates'
                         'featured';
    @media only screen and (min-width : 768px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'address nav'
                           'certificates featured';
    }
    @media only screen and (min-width : 992px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas: 'address nav certificates featured';
    }
  }

  &__address {
    grid-area: address;
    & > :last-child {
      margin-bottom: 0;
    }
  }

  &__nav {
    grid-area: nav;
    .nv-main {
      margin-bottom: 1rem;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      ul ul {
        display: none;
      }
      li {
        margin: 0;
        padding: .25em 0;
        border-bottom: solid var(--gray-darker) 1px;
      }
      a {
        display: block;
        border: 0;
        color: var(--gray-darker);
        font-weight: 300;
      }
      .current a {
        font-weight: 600;
      }
    }
    .nv-meta {
      font-size: 0.875rem;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
      }
      li {
        margin: 0;
        padding: 0;
      }
      li:not(:last-child)::after {
        content: '|';
        margin-inline: .375em;
      }
      a {
        border: 0;
        color: var(--gray-darker);
        font-weight: 300;
      }
    }
  }

  &__certificates {
    grid-area: certificates;
  }

  &__featured {
    grid-area: featured;
    a {
      border: 0;
    }
  }
}