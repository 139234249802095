.section-intro {
  $this: &;
  background: linear-gradient(to bottom, #fff 0, #fff var(--gutter), var(--blue) var(--gutter), var(--blue) 11rem, transparent 10rem, transparent 100%), var(--gray-light);
  padding-bottom: var(--section-padding);
  &__grid {
    display: grid;
    row-gap: var(--section-gap);
    column-gap: var(--gutter);
    grid-template-areas: 'teaser'
                         'header'
                         'text'
                         'image';
  }
  &__teaser {
    // margin-top: calc(var(--gutter) * -1);
    grid-area: teaser;
  }
  .cm-header {
    grid-area: header;
  }
  .cm-text {
    grid-area: text;
  }
  .cm-image {
    grid-area: image;
  }
  @media only screen and (min-width : 768px) {
    &__grid {
      grid-template-areas: 'teaser teaser teaser'
                           'header header text'
                           '. . image';
      grid-template-columns: repeat(3, 1fr);
    }
    &[data-columns="hl1-t2"] {
      #{$this}__grid {
        grid-template-areas: 'teaser teaser teaser'
                             'header text text'
                             '. image image';
        .cm-text {
          columns: 2;
          gap: var(--gutter);
        }
      }
    }
  }
}