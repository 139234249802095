.cm-slider {
  aspect-ratio: 16/9;
  background-color: var(--gray);
  &__image {
    display: block;
    width: 100%;
    height: auto;
  }
  .splide__pagination {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 var(--gutter) var(--gutter) var(--gutter);
  }
}