:root {
  --section-padding: 2rem;
  --section-gap: 2rem;
  --gutter: 1.5rem;
  
  --blue: #325A78;
  --blue-dark: #14244B;
  --blue-op50: rgba(50, 90, 120, .5);
  --gray-light: #E5E5E5;
  --gray: #ABABAB;
  --gray-dark: #969696;
  --gray-darker: #4d4d4d;

  --primary: var(--blue);
  --primary-dark: var(--blue-dark);
}

// npm
@import '../../../../node_modules/sanitize.css/sanitize.css';
@import '../../../../node_modules/@fontsource/noto-sans/200.css';
@import '../../../../node_modules/@fontsource/noto-sans/200-italic.css';
@import '../../../../node_modules/@fontsource/noto-sans/300.css';
@import '../../../../node_modules/@fontsource/noto-sans/300-italic.css';
@import '../../../../node_modules/@fontsource/noto-sans/600.css';
@import '../../../../node_modules/@fontsource/noto-sans/600-italic.css';

@import '../../../../node_modules/@fortawesome/fontawesome-pro/css/fontawesome.min.css';
@import '../../../../node_modules/@fortawesome/fontawesome-pro/css/regular.min.css';

@import '../../../../node_modules/@splidejs/splide/dist/css/splide-core.min.css';

@import '../../../../node_modules/@ryze-digital/accordion/index';


// Plugins
@import "./plugins-splide";

// Site
@import "./forms";
@import "./content-grid";
@import "./site-typo";
@import "./site-general";
@import "./site-header";
@import "./site-navbar";
@import "./site-navbar-animation";
@import "./site-footer";
@import "./site-scroll";

// Section
@import "../../../../NodeTypes/Section/Standard/Standard";
@import "../../../../NodeTypes/Section/Intro/Intro";

// Content
@import "../../../../NodeTypes/Content/Header/Header";
@import "../../../../NodeTypes/Content/Text/Text";
@import "../../../../NodeTypes/Content/Hero/Hero";
@import "../../../../NodeTypes/Content/Image/Image";
@import "../../../../NodeTypes/Content/CTA/CTA";
// @import "../../../../NodeTypes/Content/DownloadLink/DownloadLink";
@import "../../../../NodeTypes/Content/TeaserTopics/TeaserTopics";
@import "../../../../NodeTypes/Content/TeaserServices/TeaserServices";
@import "../../../../NodeTypes/Content/Facts/Facts";
@import "../../../../NodeTypes/Content/Accordion/Accordion";
@import "../../../../NodeTypes/Content/FormContact/FormContact";
@import "../../../../NodeTypes/Content/ListImageText/ListImageText";
@import "../../../../NodeTypes/Content/Slider/Slider";
@import "../../../../NodeTypes/Content/Video/Video";
@import "../../../../NodeTypes/Content/Logos/Logos";
@import "../../../../NodeTypes/Content/Downloads/Downloads";
@import "../../../../NodeTypes/Content/SimpleSearch/SimpleSearch";