.cm-list-image-text {
  row-gap: 0;
}

.cm-image-text {
  $this: &;

  &__grid {
    display: grid;
    align-items: center;
    grid-template-areas: 'image'
                         'content';
  }
  &__image {
    // background-color: var(--gray-dark);
    grid-area: image;
  }
  &__content {
    padding-block: var(--section-gap);
    grid-area: content;
  }
  &:nth-child(odd) {
    background-color: var(--gray-light);
  }

  &.has-image-padding {
    #{$this}__image {
      padding: var(--gutter);
    }
  }

  @media only screen and (min-width : 768px) {
    &__grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 'image content';
    }
    &:nth-child(odd) {
      #{$this}__content {
        padding-left: var(--section-gap);
      }
    }
    &:nth-child(even) {
      #{$this}__grid {
        grid-template-areas: 'content image';
      }
      #{$this}__content {
        padding-right: var(--section-gap);
      }
    }
  }
}