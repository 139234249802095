.cm-header {
  & > * {
    margin: 0;
    text-wrap: balance;
  }
  &__subtitle {
    font-size: 1.6667rem;
    line-height: 1.1;
    color: var(--gray-dark);
  }
}