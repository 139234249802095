.splide {
  &__arrow {
    margin: 0;
    padding: .5rem;
    border: 0;
    width: 2.6667rem;
    height: 2.6667rem;
    background: transparent;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    &--prev {
      left: var(--gutter);
    }
    &--next {
      right: var(--gutter);
      svg {
        transform: scaleX(-1);
      }
    }
    svg {
      display: block;
      width: 100%;
      height: auto;
      fill: none;
      stroke: #fff;
      stroke-width: 3;
    }
  }

  &__pagination {
    margin: 0;
    padding: var(--gutter) var(--gutter) 0 var(--gutter);
    list-style: none;
    display: flex;
    justify-content: center;
    gap: var(--gutter);
    li {
      margin: 0;
      padding: 0;
    }
    &__page {
      margin: 0;
      padding: 0;
      border: 0;
      background-color: #fff;
      width: .5rem;
      height: .5rem;
      border-radius: 50%;
      transition: background-color .2s ease;
      &:hover {
        background-color: var(--primary);
      }
      &.is-active {
        background-color: var(--primary);
      }
    }

  }
}